* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #333;
    color: #fff;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > thead > tr > th {
    background-color: #48506483;
    color: #fff;
 }
 
.form-control {
    background-color: #48506483;
    border-color: #48506483;
    color: #fff;
}

.form-control::placeholder{
    color: #aaa9a9;
}
.form-control:active,
.form-control:focus,
.form-control:focus:active {
    background-color: #48506483!important;
    color: #ffffff;
}

.t-button-nowrap {
    white-space: nowrap;
    text-align: center;
    min-width: 43px;
}

.btn-primary {
    background-color: #0047b3;
    border-color: #0a4bac;
}

.btn-danger {
    background-color: #8a202b;
    border-color: #811f29;
}

.btn-success {
    background-color: #125e3a;
    border-color: #12633d;
}

.dropdown-menu {
    background-color: #3d4455;
    
}

.dropdown-menu > .dropdown-item {
    color: #fff;
}

.dropdown-menu > .dropdown-item:hover {
    background-color: #5e6881;
}

.dropdown-menu > .list-unstyled > .dropdown-item {
    color: #fff;
}

.dropdown-menu > .list-unstyled > .dropdown-item:hover {
    background-color: #5e6881;
}

/* .pagination > .page-item > .page-link {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff;
} */

.pagination>li>a,
.pagination>li>span {
    border-color: #6c757d;
    background-color: #6c757d;
    color: #fff;
}
.active>.page-link, .page-link.active {
    background-color: #44494e;
    border-color: #44494e;
}

.modal {
    color: #fff;
    
}

.modal > .modal-dialog > .modal-content {
    background-color: #434a5cf6;
}